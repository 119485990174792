<template>
  <card>
    <div class="px-6 py-5 flex border-b border-gray-200">
      <div class="flex-1">
        <h2 class="text-lg leading-6 text-gray-900 font-medium mb-1">
          {{ t('components.assessment_invite_template.title') }}
        </h2>
        <p class="text-sm leading-5 font-normal text-gray-500">
          <i18n-t
            tag="p"
            keypath="components.assessment_invite_template.description"
          >
            <template #newline>
              <br>
            </template>
          </i18n-t>
        </p>
      </div>
      <div class="flex items-center">
        <toggle
          v-model="isEnabled"
          :disabled="disabled"
          name="enabled"
          qa-class="invite-mail-toggle"
          @click="toggleEnabled"
        />
      </div>
    </div>
    <form
      v-if="isEnabled"
      class="p-6"
    >
      <tab-group
        :selected-index="selectedTab"
        @change="changeTab"
      >
        <tab-list class="flex">
          <tab
            v-slot="{ selected }"
            as="template"
          >
            <button
              :class="['p-2 cursor-pointer text-sm font-medium flex-1',
                       selected ? 'border-primary-500 text-primary-600 border-b-2' :
                       'text-gray-500 border-gray-300 border-b-2 qa-invite-mail-tab-german']"
            >
              <div class="flex justify-center gap-2">
                <Heroicon
                  v-if="hasErrorInGermanTemplate"
                  icon="ExclamationCircleIcon"
                  aria-hidden="true"
                  class="text-orange-600 w-5 h-5"
                />
                {{ t('defaults.langs.de') }}
              </div>
            </button>
          </tab>
          <tab
            v-slot="{ selected }"
            as="template"
          >
            <button
              :class="['p-2 cursor-pointer text-sm font-medium flex-1',
                       selected ? 'border-primary-500 text-primary-600 border-b-2' :
                       'text-gray-500 border-gray-300 border-b-2 qa-invite-mail-tab-english']"
            >
              <div class="flex justify-center gap-2">
                <Heroicon
                  v-if="hasErrorInEnglishTemplate"
                  icon="ExclamationCircleIcon"
                  aria-hidden="true"
                  class="text-orange-600 w-5 h-5"
                />
                {{ t('defaults.langs.en') }}
              </div>
            </button>
          </tab>
        </tab-list>
        <tab-panels>
          <tab-panel class="space-y-5 pt-5">
            <base-input
              v-model="germanSubject"
              v-debounce:1000ms="updateMessage"
              :disabled="disabled"
              name="de[subject]"
              class="mb-5"
              :label="t('components.assessment_invite_template.subject.title')"
              type="text"
              :placeholder="getLocalizedMessage('components.assessment_invite_template.subject.placeholder', 'de')"
              :error="getError('de_subject')"
              qa-class="invite-mail-subject-german"
            />
            <base-textarea
              v-model="germanText"
              v-debounce:1000ms="updateMessage"
              :disabled="disabled"
              name="de[text]"
              :rows="16"
              class="mb-5"
              :label="t('components.assessment_invite_template.text.title')"
              :placeholder="getTextPlaceholder('de')"
              qa-class="invite-mail-text-german"
              :error="getError('de_text')"
            />
            <BaseButton
              :is-primary="false"
              :icon="sendMailButtonIcon"
              :is-disabled="disabled"
              qa-class="invite-mail-test-german"
              @click="sendTestMail('de')"
            >
              {{ sendMailButtonText }}
            </BaseButton>
          </tab-panel>
          <tab-panel class="space-y-5 pt-5">
            <base-input
              v-model="englishSubject"
              v-debounce:1000ms="updateMessage"
              :disabled="disabled"
              name="en[subject]"
              class="mb-5"
              :label="t('components.assessment_invite_template.subject.title')"
              type="text"
              :placeholder="getLocalizedMessage('components.assessment_invite_template.subject.placeholder', 'en')"
              :error="getError('en_subject')"
              qa-class="invite-mail-subject-english"
            />
            <base-textarea
              v-model="englishText"
              v-debounce:1000ms="updateMessage"
              :disabled="disabled"
              name="en[text]"
              :rows="16"
              class="mb-5"
              :label="t('components.assessment_invite_template.text.title')"
              :placeholder="getTextPlaceholder('en')"
              qa-class="invite-mail-text-english"
              :error="getError('en_text')"
            />
            <BaseButton
              :is-primary="false"
              :icon="sendMailButtonIcon"
              qa-class="invite-mail-test-english"
              :is-disabled="disabled"
              @click="sendTestMail('en')"
            >
              {{ sendMailButtonText }}
            </BaseButton>
          </tab-panel>
        </tab-panels>
      </tab-group>
    </form>
    <NotificationToolTip
      v-show="showNotificationToolTip"
      ref="notificationToolTip"
      :message="$t('defaults.updated')"
    />
  </card>
</template>

<script setup>
import {
  TabGroup, TabList, Tab, TabPanels, TabPanel,
} from '@headlessui/vue';
import Axios from 'axios';
import Toggle from '@/components/generic/Toggle/Toggle.vue';
import NotificationToolTip from '@/components/NotificationToolTip.vue';
import {
  useTemplateRef, ref, toRefs, inject, computed,
} from 'vue';
import {
  assessmentMessageTemplatesPath,
  assessmentMessageTemplatesTestPath,
} from '@/util/url-helpers';
import { useI18n } from 'vue-i18n';
import vueDebounce from 'vue-debounce';
import { getLocalizedMessage } from '@/plugins/i18n';
import Heroicon from '@/components/Heroicon.vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';

const props = defineProps({
  template: {
    type: Object,
    required: true,
  },
  disabled: {
    type: Boolean,
    required: true,
  },
});

const toast = inject('toast');

const vDebounce = vueDebounce({ lock: true, defaultTime: '1000ms' });

const { template } = toRefs(props);

const isEnabled = ref(template.value.enabled);

const germanSubject = ref(template.value.de_subject);
const englishSubject = ref(template.value.en_subject);

const germanText = ref(template.value.de_text);
const englishText = ref(template.value.en_text);

const errors = ref({});
const getError = (...keys) => errors.value[keys.join('.')]?.[0];

const hasErrorInGermanTemplate = computed(() => !!errors.value.de_subject || !!errors.value.de_text);
const hasErrorInEnglishTemplate = computed(() => !!errors.value.en_subject || !!errors.value.en_text);

const notificationToolTip = useTemplateRef('notificationToolTip');
const showNotificationToolTip = ref(false);

const { t } = useI18n();

const sendMailButtonText = ref(t('components.assessment_invite_template.test_mail.call_to_action'));
const sendMailButtonIcon = ref('MailIcon');

const getTextPlaceholder = (localisation) => {
  const greeting = getLocalizedMessage('components.assessment_invite_template.text.placeholder.greeting', localisation);
  const text = getLocalizedMessage('components.assessment_invite_template.text.placeholder.text', localisation);
  return `${greeting}\n\n${text}`;
};

const toggleEnabled = () => {
  Axios.put(assessmentMessageTemplatesPath(template.value.assessment_id), {
    enabled: isEnabled.value,
  }).then(() => {
    showNotificationToolTip.value = true;
    notificationToolTip.value.triggerNotification();
    errors.value = {};
  }).catch(({ response }) => {
    if (response?.data?.errors) {
      errors.value = response.data.errors;
    }
    toast.error(t('components.assessment_invite_template.error.toggle'));
  });
};

const updateMessage = () => {
  Axios.put(assessmentMessageTemplatesPath(template.value.assessment_id), {
    enabled: isEnabled.value,
    de_subject: germanSubject.value,
    de_text: germanText.value,
    en_subject: englishSubject.value,
    en_text: englishText.value,
  }).then(() => {
    errors.value = {};
    showNotificationToolTip.value = true;
    notificationToolTip.value.triggerNotification();
  }).catch(({ response }) => {
    if (response?.data?.errors) {
      errors.value = response.data.errors;
    }
    toast.error(t('components.assessment_invite_template.error.save'));
  });
};

const sendTestMail = (locale) => {
  sendMailButtonText.value = '...';
  sendMailButtonIcon.value = undefined;

  Axios.post(assessmentMessageTemplatesTestPath(template.value.assessment_id), {
    locale,
  }).then(() => {
    errors.value = {};
    sendMailButtonText.value = t('components.assessment_invite_template.test_mail.success');
    sendMailButtonIcon.value = 'CheckIcon';

    setTimeout(() => {
      sendMailButtonText.value = t('components.assessment_invite_template.test_mail.call_to_action');
      sendMailButtonIcon.value = 'MailIcon';
    }, 3000);
  }).catch((response) => {
    if (response?.data?.errors) {
      errors.value = response.data.errors;
    }
    toast.error(t('components.assessment_invite_template.test_mail.error'));
  });
};

const selectedTab = ref(0);

const changeTab = (index) => {
  selectedTab.value = index;
};
</script>
