<template>
  <transition
    name="slide-in-right"
    mode="out-in"
    enter-class="animate-slide-in-right"
    leave-class="animate-slide-out-right"
  >
    <div
      v-if="show"
      class="fixed z-[70] bottom-4 right-4 flex justify-center qa-notification-tool-tip"
    >
      <div
        class="flex bg-white border border-black-900 py-2 px-4
       rounded-full shadow-lg justify-between align-middle gap-x-2"
      >
        <div class="">
          <CheckCircleIcon
            class="h-6 w-5 text-green-500"
            aria-hidden="true"
          />
        </div>
        <div class="text-black-900 ">
          {{ message }}
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { ref, onMounted } from 'vue';

export default {
  name: 'NotificationToolTip',
  components: {
    CheckCircleIcon,
  },
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  setup() {
    const show = ref(false);

    let timer;

    const triggerNotification = () => {
      show.value = true;
      clearTimeout(timer);
      timer = setTimeout(() => {
        show.value = false;
      }, 2000);
    };

    onMounted(() => {
      triggerNotification();
    });

    return {
      show,
      triggerNotification,
    };
  },
};
</script>

<style>
@keyframes slide-in-right {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

.slide-in-right-enter-active {
  animation: slide-in-right 0.3s;
}

.slide-in-right-leave-active {
  animation: slide-out-right 0.3s;
}
</style>
