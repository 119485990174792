<template>
  <div>
    <div
      v-if="label || hint"
      :class="['flex', label ? 'justify-between' : 'justify-end' ]"
    >
      <label
        v-if="label"
        :for="id"
        class="block text-sm font-medium text-gray-700"
      >
        {{ label }}
      </label>
      <span
        v-if="hint"
        class="text-sm text-gray-500"
      >
        {{ hint }}
      </span>
    </div>
    <RadioGroup v-model="selectedColor">
      <div class="mt-4 inline-flex items-center flex-wrap gap-3">
        <RadioGroupOption
          v-for="color in colors"
          :key="color.name"
          v-slot="{ active, checked }"
          as="template"
          :value="color"
        >
          <div
            :class="[`${color.selectedColor}`, active && checked ? 'ring ring-offset-1' : '',
                     !active && checked ? 'ring-2' : '',
                     `-m-0.5 relative p-0.5 rounded-full flex items-center
                     justify-center cursor-pointer focus:outline-none`]"
          >
            <RadioGroupLabel
              as="p"
              class="sr-only"
            >
              {{ color.name }}
            </RadioGroupLabel>
            <span
              aria-hidden="true"
              :class="[`${color.bgColor}`, 'h-8 w-8 border border-black border-opacity-10 rounded-full']"
            />
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
    <div class="relative">
      <input
        :id="id"
        type="text"
        :value="selectedColor.name"
        :name="name"
        :form="form"
        :disabled="disabled"
        class="sr-only"
      >
    </div>

    <p
      v-if="error"
      class="mt-2 text-sm text-red-600"
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { defineComponent, ref, toRefs, computed } from 'vue';
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue';
import { nameToTailwind, TAILWIND_COLOR_OPTIONS } from './tailwindColors';

export default defineComponent({
  name: 'RadioColorPicker',
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
  },
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    customPalette: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    form: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, customPalette } = toRefs(props);
    const colors = computed(() => {
      if (customPalette.value.length > 0) return customPalette.value;
      return TAILWIND_COLOR_OPTIONS;
    });
    const selectedColor = ref(null);

    if (modelValue.value != null && customPalette.value.length > 0) {
      selectedColor.value = customPalette.value.find((color) => color.name === modelValue.value);
    } else {
      selectedColor.value = nameToTailwind(modelValue.value) ?? colors.value?.[0];
    }

    const selectColor = (newColor) => {
      selectedColor.value = newColor;
      emit('update:modelValue', newColor);
    };

    return {
      selectedColor,

      colors,
      selectColor,
    };
  },
});
</script>
