<template>
  <div>
    <div class="px-4 py-5">
      <div class="-ml-4 -mt-2 flex items-center justify-between flex-wrap sm:flex-nowrap">
        <div class="mt-2">
          <h3 class="text-lg leading-6 ml-6 font-medium text-gray-900">
            {{ $t('components.test_list.title') }}
          </h3>
          <p
            class="text-sm leading-6 ml-6 text-gray-500 font-normal"
          >
            {{ $t('components.test_list.help_text') }}
          </p>
        </div>
        <div class="ml-4 mt-2 shrink-0">
          <BaseButton
            :is-disabled="disabled"
            icon="PlusIcon"
            qa-class="add-test-button"
            @click="openAddTestSlideOver"
          >
            {{ $t('components.test_list.add_test') }}
          </BaseButton>
        </div>
      </div>
    </div>
    <div class="relative w-full max-w-full overflow-y-visible md:overflow-visible">
      <div class="flex w-full h-full overflow-x-auto md:overflow-x-visible">
        <table
          :class="['table-auto w-full text-left']"
        >
          <colgroup>
            <col
              span="1"
              class="w-auto"
            >
            <col
              span="3"
              class="w-auto"
            >
            <col
              span="1"
              class="w-auto"
            >
            <col
              span="1"
              class="w-auto"
            >
          </colgroup>

          <thead class="text-sm uppercase text-gray-500 border-t border-gray-300">
            <tr class="children:py-3">
              <th class="pl-6">
                {{ $t('components.test_list.test') }}
              </th>
              <th class="min-w-40">
                <div class="flex flex-row items-center">
                  {{ $t('components.test_list.link') }}
                  <ClipboardCopyIcon
                    class="text-gray-300 hover:text-gray-500 cursor-pointer w-6 ml-2"
                    @click="copyAllLinks($event)"
                  />
                </div>
              </th>
              <th class="min-w-40">
                {{ $t('components.test_list.status') }}
              </th>
              <th />
            </tr>
          </thead>
          <tbody />

          <tbody v-if="hasTests">
            <tr
              v-for="test in testList"
              :key="test.id"
              class="children:h-20 children:border-t children:py-4 text-sm align-middle"
            >
              <td class="pl-6">
                <div class="flex flex-row items-center">
                  {{ test.name }}
                </div>
              </td>

              <td>
                <div class="flex flex-row justify-start items-center">
                  <p class="w-min">
                    {{ test.url }}
                  </p>
                  <ClipboardCopyIcon
                    class="text-gray-300 hover:text-gray-500 cursor-pointer w-6 ml-2"
                    @click="copyLink(test.url, $event)"
                  />
                </div>
              </td>

              <td>
                <div class="flex justify-start m-2">
                  <TestStatus
                    :test-status="test.status "
                  />
                </div>
              </td>
              <td>
                <div class="relative">
                  <TestActions
                    :test="test"
                    :assessment-id="assessmentId"
                    :candidate-id="candidate.id"
                    @edit="openEditTestSlideOver(test)"
                    @continue="continueTest(test)"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div
      v-if="!hasTests"
      class="flex flex-col items-center justify-center py-8"
    >
      <div
        class="text-center object-center content-center justify-center space-y-6"
      >
        <div>
          <p
            class="text-gray-900 text-base leading-5 font-medium"
          >
            {{ $t('components.test_list.empty_state_header') }}
          </p>
        </div>
        <div>
          <base-button
            icon="PlusIcon"
            :is-disabled="disabled"
            @click="openAddTestSlideOver"
          >
            {{ $t('components.test_list.add_test') }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
  <TestSlideOver
    v-if="showAddTestSlideOver"
    v-model:open="showAddTestSlideOver"
    :test="slideOverTest"
    :assessment-id="assessmentId"
    :candidate="candidate"
    :sex-options="sexOptions"
    @created="onTestCreated"
    @updated="onTestUpdated"
  />
</template>

<script>
import {
  defineComponent, ref, inject, watch, computed,
} from 'vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import TestStatus from '@/components/Candidates/EditCandidate/TestStatus.vue';
import TestActions from '@/components/Candidates/EditCandidate/TestActions.vue';
import { ClipboardCopyIcon } from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';
import { copyText } from '@/util/clipboard';
import Axios from 'axios';
import TestSlideOver from './TestSlideOver.vue';
import { continueTestPath } from '../../../util/url-helpers';

export default defineComponent({
  components: {
    BaseButton,
    TestStatus,
    ClipboardCopyIcon,
    TestActions,
    TestSlideOver,
  },
  props: {
    tests: {
      type: Array,
      required: true,
    },
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    candidate: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: true,
    },
    sexOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const toast = inject('toast');
    const { t } = useI18n();

    const testList = ref(props.tests);

    const hasTests = computed(() => testList.value.length > 0);

    // SlideOver State
    const showAddTestSlideOver = ref(false);
    const slideOverTest = ref(null);

    watch(() => showAddTestSlideOver.value, (open) => {
      if (!open) {
        slideOverTest.value = null;
      }
    });

    const openAddTestSlideOver = () => {
      slideOverTest.value = null;
      showAddTestSlideOver.value = true;
    };

    const openEditTestSlideOver = (test) => {
      slideOverTest.value = test;
      showAddTestSlideOver.value = true;
    };

    const onTestCreated = (test) => {
      testList.value.push(test);
    };

    const onTestUpdated = (test) => {
      testList.value[testList.value.findIndex((item) => item.id === test.id)] = test;
    };

    // Copy Links to Clipboard
    const copyLink = (link, event) => {
      copyText(link, event.element);
      toast.notice(
        t('components.test_list.link_copied'),
      );
    };

    const copyAllLinks = (event) => {
      let textToCopy = '';

      testList.value.forEach((test) => {
        textToCopy += `${test.name} ${test.url}\n`;
      });

      copyText(textToCopy, event.element);
      toast.notice(
        t('components.test_list.link_copied'),
      );
    };

    const continueTest = (test) => new Promise((resolve) => {
      Axios.put(continueTestPath(props.assessmentId, props.candidate.id, test))
        .then((response) => {
          onTestUpdated(response.data);

          toast.notice(
            t('components.test_list.action.continue.test_was_continued'),
          );
          resolve();
        })
        .catch(() => {
          toast.error(
            t('components.test_list.action.continue.failed_to_continue_test'),
          );
        });
    });

    return {
      testList,
      hasTests,

      showAddTestSlideOver,
      openAddTestSlideOver,
      openEditTestSlideOver,
      onTestCreated,
      onTestUpdated,
      slideOverTest,
      continueTest,

      copyLink,
      copyAllLinks,
    };
  },
});
</script>
