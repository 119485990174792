<template>
  <SlideOver
    :open="isOpen"
    @click-outside="closeSlideOver"
    @close-clicked="closeSlideOver"
  >
    <template #title>
      {{ title }}
    </template>
    <template #content>
      <LoadingSlider
        v-show="isLoading"
      />
      <div class="h-full flex flex-col justify-between">
        <div class="m-4 space-y-5 divide-y divide-gray-200">
          <div class="space-y-6">
            <SelectList
              v-if="candidateSexSelectRequired"
              v-model:selected="candidateSex"
              :label="$t('activerecord.attributes.candidate.sex')"
              :options="sexOptions"
              name="candidate[sex]"
            />
            <SearchableSelectList
              v-if="!test"
              id="test-select"
              :options="testsInCatalog"
              :allow-reset="false"
              label-prop="testFormTitle"
              secondary-label-prop="secondaryLabel"
              value-prop="id"
              :label="$t('components.test_list.test')"
              name="testFormTitle"
              @update:selected="selectedTest = $event"
            />
            <TestOptionsHogrefe
              v-if="isHogrefeTestProvider"
              :test="test"
              :selected-test="selectedTest"
              @is-loading="isLoading = $event"
              @test-options-complete="testOptionsComplete = $event;"
              @update:test-options="testOptions = $event;"
            />
          </div>
          <div
            v-if="test || selectedTest"
            class="space-y-4"
          >
            <label
              class="block text-sm font-medium text-gray-700 mt-4"
            >
              {{ $t('components.test_list.candidate_portal.candidate_portal') }}
            </label>
            <BaseCheckbox
              v-model="forCandidate"
              :label="$t('components.test_list.candidate_portal.for_candidate')"
              :secondary-label="$t('components.test_list.candidate_portal.for_candidate_help')"
            />
            <BaseCheckbox
              v-if="forCandidate"
              v-model="downloadable"
              :label="$t('components.test_list.candidate_portal.downloadable')"
              :secondary-label="$t('components.test_list.candidate_portal.downloadable_help')"
            />
            <BaseCheckbox
              v-if="forCandidate"
              v-model="textSelectable"
              :label="$t('components.test_list.candidate_portal.text_selectable')"
              :secondary-label="$t('components.test_list.candidate_portal.text_selectable_help')"
            />
          </div>
          <div
            v-if="candidates && (test || selectedTest)"
            class="space-y-4"
          >
            <TriStateCheckbox
              id="checkbox"
              class="mt-3"
              :label="$t('components.test_list.candidates')"
              :is-intermediate="someCandidatesSelected"
              :model-value="allCandidatesSelected"
              @update:model-value="updateCandidatesSelected"
            />
            <BaseCheckbox
              v-for="checkBoxCandidate in candidates"
              :key="checkBoxCandidate.id"
              class="ml-2"
              label-classes="ph-no-capture text-gray-700 flex flex-col"
              secondary-label-classes="text-red-500 font-normal"
              :label="checkBoxCandidate.name"
              :secondary-label="candidateValidationError(checkBoxCandidate)"
              :disabled="!candidateIsValid(checkBoxCandidate)"
              :model-value="selectedCandidateIds.includes(checkBoxCandidate.id)"
              @update:model-value="updateCandidates($event, checkBoxCandidate)"
            />
          </div>
        </div>
        <div class="space-x-5 flex justify-end border-t border-gray-200 p-5">
          <BaseButton
            :is-primary="false"
            :is-disabled="isSubmitting"
            @click="closeSlideOver"
          >
            {{ $t('defaults.cancel') }}
          </BaseButton>
          <BaseButton
            name="submit"
            type="submit"
            :is-loading="isSubmitting"
            :is-disabled="!formComplete"
            @click="submit"
          >
            <slot>
              {{ submitButtonLabel }}
            </slot>
          </BaseButton>
        </div>
      </div>
    </template>
  </SlideOver>
</template>
<script>
import {
  defineComponent, ref, computed, inject, onMounted, watch,
} from 'vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import SlideOver from '@/components/generic/SlideOver/SlideOver.vue';
import SearchableSelectList from '@/components/generic/SearchableSelectList/SearchableSelectList.vue';
import BaseCheckbox from '@/components/generic/BaseCheckbox/BaseCheckbox.vue';
import SelectList from '@/components/generic/SelectList/SelectList.vue';

import {
  testsPath,
  assessmentCandidateTestsPath,
  assessmentCandidateTestPath,
  assessmentTestBulkCreate,
} from '@/util/url-helpers';
import { useI18n } from 'vue-i18n';
import Axios from 'axios';
import LoadingSlider from '@/components/Candidates/EditCandidate/LoadingSlider.vue';
import TestOptionsHogrefe from './TestOptionsHogrefe.vue';

export default defineComponent({
  components: {
    BaseButton,
    SlideOver,
    SearchableSelectList,
    BaseCheckbox,
    LoadingSlider,
    SelectList,
    TestOptionsHogrefe,
  },
  props: {
    test: {
      type: Object,
      required: false,
      default: null,
    },
    open: {
      type: Boolean,
      default: false,
    },
    assessmentId: {
      type: [Number, String],
      required: true,
    },
    candidate: {
      type: Object,
      required: false,
      default: null,
    },
    candidates: {
      type: Array,
      required: false,
      default: null,
    },
    sexOptions: {
      type: Array,
      required: true,
    },
  },
  emits: ['created', 'updated', 'update:open'],
  setup(props, { emit }) {
    const { t } = useI18n();

    const isOpen = ref(props.open);
    watch(() => props.open, (newVal) => {
      isOpen.value = newVal;
    });

    const selectedTest = ref(props.test);
    const testsInCatalog = ref([]);

    const testOptions = ref(null);
    const testOptionsComplete = ref(false);

    const forCandidate = ref(false);
    const downloadable = ref(false);
    const textSelectable = ref(false);

    const selectedCandidateIds = ref([]);
    const candidateSex = ref(null);

    const isLoading = ref(true);
    const isSubmitting = ref(false);

    function updateCandidates(value, candidate) {
      if (value) {
        selectedCandidateIds.value.push(candidate.id);
      } else {
        selectedCandidateIds.value.splice(selectedCandidateIds.value.indexOf(candidate.id), 1);
      }
    }

    const candidateSexSelectRequired = computed(() => props.candidate != null && props.candidate.sex === null);
    const isHogrefeTestProvider = computed(() => props.test?.testProviderName === 'Hogrefe'
     || selectedTest?.value?.testProviderName === 'Hogrefe');
    const testOptionsFulfilled = computed(() => (isHogrefeTestProvider.value ? testOptionsComplete.value : true));

    const hasCandidateList = computed(() => props.candidates?.length);
    const formComplete = computed(() => (
      (props.test || selectedTest.value) && (candidateSexSelectRequired.value ? candidateSex.value !== null : true)
      && testOptionsFulfilled.value && (hasCandidateList.value ? selectedCandidateIds.value.length > 0 : true)));

    const candidateValidationError = (candidate) => {
      if (candidate.sex === null) {
        return t('components.test_list.action.create.sex_is_missing');
      }
      return null;
    };

    const candidateIsValid = (candidate) => candidateValidationError(candidate) === null;

    const allCandidatesSelected = computed(() => selectedCandidateIds.value.length === props.candidates?.length);
    const someCandidatesSelected = computed(() => selectedCandidateIds.value.length > 0
                                                  && !allCandidatesSelected.value);
    const updateCandidatesSelected = (isChecked) => {
      if (isChecked) {
        selectedCandidateIds.value = props.candidates
          .filter((candidate) => candidateIsValid(candidate))
          .map((candidate) => candidate.id);
      } else {
        selectedCandidateIds.value = [];
      }
    };

    const resetForm = () => {
      candidateSex.value = null;
      testOptions.value = null;
      selectedTest.value = null;

      forCandidate.value = false;
      downloadable.value = false;
      textSelectable.value = false;
    };

    const toast = inject('toast');

    const fetchTestCatalog = async () => {
      isLoading.value = true;

      try {
        const response = await Axios.get(testsPath());
        isLoading.value = false;
        testsInCatalog.value = response.data.testForms;

        if (response.data.errors) {
          toast.error(response.data.errors);
        }
      } catch (error) {
        toast.error(
          error.response?.data?.error || t('components.test_list.action.create.failed_to_create_test'),
        );
      }
    };

    const closeSlideOver = () => {
      isOpen.value = false;
      setTimeout(() => {
        // Wait for animation to finish
        resetForm();
        emit('update:open', false);
      }, 500);
    };

    const createTest = async () => {
      isSubmitting.value = true;
      isLoading.value = true;

      const data = {
        testProviderName: selectedTest.value.testProviderName,
        name: selectedTest.value.testFormTitle,
        testFormId: selectedTest.value.testFormId,
        testId: selectedTest.value.testId,
        for_candidate: forCandidate.value,
        downloadable: downloadable.value,
        text_selectable: textSelectable.value,
      };

      if (candidateSexSelectRequired.value) {
        data.candidate = { sex: candidateSex.value.value };
      }

      if (testOptionsComplete.value) {
        data.test_options = testOptions.value;
      }

      if (props.candidates != null) {
        data.candidate_ids = selectedCandidateIds.value;
      }

      const url = props.candidates != null
        ? assessmentTestBulkCreate(props.assessmentId)
        : assessmentCandidateTestsPath(props.assessmentId, props.candidate.id);

      try {
        const response = await Axios.post(url, data);
        isLoading.value = false;
        isSubmitting.value = false;

        emit('created', response.data);

        closeSlideOver();

        if (props.candidates != null) {
          const { tests, errors } = response.data;

          if (tests?.length > 0 && errors?.length > 0) {
            toast.notice(
              // concat errors
              `${t('components.test_list.action.create.test_was_created')} \n
              ${errors.reduce((acc, curr) => acc.concat(curr.error), []).join('\n')}`,
            );
          } else if (tests?.length === 0 && errors?.length > 0) {
            toast.error(
              // concat errors
              errors.reduce((acc, curr) => acc.concat(curr.error), []).join('\n'),
            );
          } else {
            toast.success(
              t('components.test_list.action.create.test_was_created'),
            );
          }
          // Reload after 2500ms to avoid hiding the toast message instantly
          setTimeout(() => {
            window.location.reload();
          }, 2500);
        } else {
          toast.success(
            t('components.test_list.action.create.test_was_created'),
          );

          // Reload page to update candidate sex in form
          if (candidateSexSelectRequired.value) {
            // Reload after 2500ms to avoid hiding the toast message instantly
            setTimeout(() => {
              window.location.reload();
            }, 2500);
          }
        }
      } catch (error) {
        isSubmitting.value = false;
        isLoading.value = false;

        toast.error(
          error.response?.data?.error || t('components.test_list.action.create.failed_to_create_test'),
        );
      }
    };

    const updateTest = async () => {
      isSubmitting.value = true;
      isLoading.value = true;

      const data = {
        test_options: testOptions.value,
        for_candidate: forCandidate.value,
        downloadable: downloadable.value,
        text_selectable: textSelectable.value,
      };

      try {
        const response = await Axios.put(
          assessmentCandidateTestPath(props.assessmentId, props.candidate.id, props.test),
          data,
        );
        isSubmitting.value = false;
        isLoading.value = false;

        emit('updated', response.data);

        closeSlideOver();
        toast.success(
          t('components.test_list.action.update.test_was_updated'),
        );
      } catch (error) {
        isSubmitting.value = false;
        isLoading.value = false;

        toast.error(
          error.response?.data?.error || t('components.test_list.action.update.failed_to_update_test'),
        );
      }
    };

    const submit = () => {
      if (props.test) {
        updateTest();
      } else {
        createTest();
      }
    };

    onMounted(() => {
      fetchTestCatalog();
      if (props.candidates != null) {
        selectedCandidateIds.value = props.candidates
          .filter((candidate) => candidateIsValid(candidate))
          .map((candidate) => candidate.id);
      }
    });

    watch(() => props.test, async (newVal) => {
      if (newVal) {
        forCandidate.value = newVal.metadata.candidate_portal.for_candidate;
        downloadable.value = newVal.metadata.candidate_portal.downloadable;
        textSelectable.value = newVal.metadata.candidate_portal.text_selectable;
      }
    });

    const title = computed(() => {
      if (props.test) {
        return props.test.name;
      }
      return t('components.test_list.add_test');
    });

    const submitButtonLabel = computed(() => {
      if (props.test) {
        return t('defaults.save');
      }
      return t('components.test_list.add_test');
    });

    return {
      title,
      submitButtonLabel,

      isOpen,
      closeSlideOver,

      testsInCatalog,

      selectedTest,
      selectedCandidateIds,
      updateCandidates,
      someCandidatesSelected,
      allCandidatesSelected,
      updateCandidatesSelected,
      isHogrefeTestProvider,

      formComplete,

      submit,
      isSubmitting,
      isLoading,

      testOptions,
      testOptionsComplete,
      forCandidate,
      downloadable,
      textSelectable,
      candidateIsValid,
      candidateValidationError,
      candidateSexSelectRequired,
      candidateSex,
    };
  },
});
</script>
