<template>
  <Card>
    <div class="px-4 py-5">
      <div class="space-y-6">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            {{ $t('components.candidates.candidate_form.categories.candidate_portal.header') }}
          </h3>
          <p
            class="text-sm leading-6  text-gray-500 font-normal"
          >
            {{ $t('components.candidates.candidate_form.categories.candidate_portal.helptext') }}
          </p>
        </div>
        <div class="flex items-center space-x-6">
          <div class="flex-1">
            <BaseInput
              :model-value="candidate.accessLink"
              :label="$t('components.candidates.candidate_form.categories.candidate_portal.access_link')"
              :placeholder="$t(
                'components.candidates.candidate_form.categories.candidate_portal.access_link_placeholder'
              )"
              :disabled="true"
              qa-class="candidate-portal-link"
            >
              <template
                v-if="candidate.accessLink"
                #addon-button
              >
                <CopyToClipboardButton
                  :text-to-copy="candidate.accessLink"
                />
              </template>
            </BaseInput>
          </div>
          <div class="flex-1">
            <BaseInput
              :model-value="candidate.accessCode"
              :label="$t('components.candidates.candidate_form.categories.candidate_portal.access_code')"
              :placeholder="$t(
                'components.candidates.candidate_form.categories.candidate_portal.access_code_placeholder'
              )"
              :disabled="true"
              qa-class="candidate-portal-access-code"
            >
              <template
                v-if="candidate.accessCode"
                #addon-button
              >
                <CopyToClipboardButton
                  :text-to-copy="candidate.accessCode"
                />
              </template>
            </BaseInput>
          </div>
        </div>
        <BaseInput
          v-model="candidate.email"
          name="candidate[email]"
          :disabled="assessmentCompleted"
          :label="$t('activerecord.attributes.candidate.email')"
          :error="getError('email')"
          :hint="$t('defaults.optional')"
          qa-class="candidate-email"
        />
      </div>
    </div>
  </Card>
</template>
<script>
import Card from '@/components/generic/Card/Card.vue';
import CopyToClipboardButton from '@/components/generic/CopyToClipboardButton/CopyToClipboardButton.vue';
import BaseInput from '@/components/generic/BaseInput/BaseInput.vue';

export default {
  components: {
    Card,
    CopyToClipboardButton,
    BaseInput,
  },
  props: {
    candidateData: {
      type: Object,
      default: () => ({
        email: '',
        accessCode: '',
        accessLink: '',
      }),
    },
    assessmentCompleted: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const getError = (...keys) => props.errors[keys.join('.')]?.[0];
    const candidate = { ...props.candidateData };

    return {
      getError,
      candidate,
    };
  },
};

</script>
