import ConfirmDialogModal from '@/components/generic/ConfirmDialogModal/ConfirmDialogModal.vue';
import { getCurrentVueApp } from '@/main';
import { reactive, createVNode, render, ref } from 'vue';

// eslint-disable-next-line import/prefer-default-export
export function useConfirmDialogModal() {
  const modalData = reactive({
    isShown: false,
    props: {},
    onConfirm: null,
    onClose: null,
  });
  const container = ref(null);

  function closeConfirmModal() {
    modalData.isShown = false;
    if (container.value) {
      const vnode = createVNode(ConfirmDialogModal, { ...modalData.props, open: false });
      // eslint-disable-next-line no-underscore-dangle
      vnode.appContext = getCurrentVueApp()._context;
      render(vnode, container.value);
      // remove the container from the DOM
      setTimeout(() => {
        try {
          document.body.removeChild(container.value);
          container.value = null;
        } catch (e) {
          // modal is already removed, do nothing
        }
      }, 150);
    }
  }

  function openConfirmModal(options) {
    modalData.isShown = true;
    modalData.props = { ...options };

    if (options.onConfirm) {
      modalData.onConfirm = options.onConfirm;
    } else {
      modalData.onConfirm = () => {
        options.sourceElement.style.display = 'none';
        document.body.appendChild(options.sourceElement);
        options.sourceElement.click();
      };
    }

    if (options.onClose) {
      modalData.onClose = options.onClose;
    } else {
      modalData.onClose = () => {
        closeConfirmModal();
      };
    }

    if (!container.value) {
      container.value = document.createElement('div');
      document.body.appendChild(container.value);
    }
    const vnode = createVNode(ConfirmDialogModal, {
      ...modalData.props,
      onConfirm: modalData.onConfirm,
      onClose: modalData.onClose,
    });
    // eslint-disable-next-line no-underscore-dangle
    vnode.appContext = getCurrentVueApp()._context; // Use the app context from the existing Vue app
    render(vnode, container.value);
  }

  return { openConfirmModal, closeConfirmModal };
}
