import { createI18n } from 'vue-i18n';

export const { defaultLocale, locale, translations } = window.i18n;

const i18n = createI18n({
  availableLocales: Object.keys(translations),
  locale,
  fallbackLocale: defaultLocale,
  messages: translations,
  globalInjection: true,
});

export const useI18n = () => i18n.global;

export const getLocalizedMessage = (
  key,
  localization = 'en',
  pluralization = 1,
) => i18n.global.t(
  key,
  pluralization,
  { locale: localization },
);

export default i18n;
