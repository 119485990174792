<template>
  <BaseButton
    :is-primary="false"
    :icon="icon"
    icon-style="text-gray-400"
    @click="copyToClipboard($event)"
  />
</template>
<script>
import { ref } from 'vue';
import BaseButton from '@/components/generic/BaseButton/BaseButton.vue';
import { copyText } from '@/util/clipboard';

export default {
  name: 'CopyToClipboardButton',
  components: {
    BaseButton,
  },
  props: {
    textToCopy: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const icon = ref('ClipboardCopyIcon');

    const copyToClipboard = (event) => {
      copyText(props.textToCopy, event.target);

      icon.value = 'CheckIcon';

      setTimeout(() => {
        icon.value = 'ClipboardCopyIcon';
      }, 3000);
    };

    return {
      icon,
      copyToClipboard,
    };
  },
};
</script>
